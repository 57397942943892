import Footer from "../Components/footer";
import Header from "../Components/header";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Piercing = () => {    
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [open5, setOpen5] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleOpen2 = () => {
        setOpen2(!open2)
    }
    const handleOpen3 = () => {
        setOpen3(!open3)
    }
    const handleOpen4 = () => {
        setOpen4(!open4)
    }
    const handleOpen5 = () => {
        setOpen5(!open5)
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="content">
                <div className="divisor">
                    <div className="divisorTitle1">TUDO SOBRE</div>
                    <div className="divisorTitle2"><hr className="line1" style={{width:100}}/>PIERCINGS</div>
                    <div className="divisorSubTitle">SAIBA UM POUCO MAIS SOBRE O MUNDO DOS PIERCINGS!</div>
                </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen}>
                            <div className="question-title">CUIDADOS E CICATRIZAÇÃO</div>
                            <div className={`icon-container ${open ? 'open':''}`}>
                                {open ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open ? 'open':''}`}>
                            <div className="answer-text">Ao fazer uma perfuração para um piercing, é essencial seguir cuidados específicos para evitar infecções e promover uma cicatrização saudável. Nosso estúdio conta com materiais esterilizados e com profissionais qualificados e que sigam rigorosamente as normas de higiene. Após a perfuração, mantenha a área limpa, lavando-a suavemente com água e sabão neutro, ou conforme as orientações do profissional. Evite tocar o piercing com as mãos sujas e não remova a joia durante o processo de cicatrização. Evite piscinas, saunas e banhos de mar nas primeiras semanas, pois esses ambientes podem introduzir bactérias na perfuração. Use roupas largas que não irritem ou pressionem a área e evite dormir sobre o piercing. Em caso de sinais de infecção, como vermelhidão excessiva, inchaço, dor intensa ou secreção, consulte um profissional de saúde imediatamente. Seguindo esses cuidados, você pode garantir uma cicatrização tranquila e segura.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen2}>
                            <div className="question-title">QUAL JOIA ESCOLHER</div>
                            <div className={`icon-container ${open2 ? 'open':''}`}>
                                {open2 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open2 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen3}>
                            <div className="question-title">ORIGEM DOS PIERCINGS</div>
                            <div className={`icon-container ${open3 ? 'open':''}`}>
                                {open3 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open3 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen4}>
                            <div className="question-title">PIERCING PODE SER REMOVIDO?</div>
                            <div className={`icon-container ${open4 ? 'open':''}`}>
                                {open4 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open4 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen5}>
                            <div className="question-title">QUALIDADE DAS JOIAS</div>
                            <div className={`icon-container ${open5 ? 'open':''}`}>
                                {open5 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open5 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                
                <div className="agende">
                    <div className="agendeTitle1">AGENDE UM<hr className="line2-agende"/></div>
                    <div className="agendeTitle2"><hr className="line1-agende"/>HORÁRIO</div>
                    <div className="agendarSubTitle">GARANTIA DE QUALIDADE EM SUA TATTOO OU APLICAÇÃO DE PIERCINGS!</div>
                    <div className="blockBtnAgendar">
                        <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                        <div className="btnAgenderBorder2"/>
                    </div>
                </div>
            </div>  
            <Footer page='PIERCING'/>    
        </div>
    )
}
export default Piercing;