import Footer from "../Components/footer";
import Header from "../Components/header";

const SobreNos = () => {    
    
    return (
        <div className="container">
            <Header/>
            <div className="content">
                <div className="divisor">
                    <div className="divisorTitle1">SOBRE NOSSO</div>
                    <div className="divisorTitle2"><hr className="line1" style={{width:100}}/>ESTÚDIO</div>
                    <div className="divisorSubTitle">SAIBA UM POUCO MAIS SOBRE NÓS!</div>
                </div>
                <div className="container-sobrenos">
                    <div className="sobrenos">
                        <img src="./images/fotostudio.png" className="fotostudio"/>
                    </div>
                    <div className="sobrenos-info">
                        <div className="sobrenos-title">NOSSA HISTÓRIA</div>
                        <hr className="sobrenos-line"/>
                        <div className="sobrenos-text">Studio Tattoo foi fundado por Lucas Martins, um artista com mais de 15 anos de experiência e uma paixão por transformar histórias de vida em arte corporal. Localizado no coração da cidade, o estúdio se destaca pela excelência técnica, segurança e compromisso em criar tatuagens personalizadas e significativas.

                        Com uma equipe talentosa, o estúdio oferece um ambiente acolhedor e criativo, onde cada tatuagem é tratada como uma obra de arte única. Do minimalismo ao realismo, o Arte na Pele é o lugar onde você pode expressar sua identidade de forma autêntica e permanente.</div>
                    </div>
                </div>
            </div>
            <Footer page='SOBRENOS'/>
        </div>
    )
}
export default SobreNos;