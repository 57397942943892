import Grid from '@mui/material/Grid2';
import Footer from "../Components/footer";
import Header from "../Components/header";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Orcamento = () => {    
    
    return (
        <div className="container">
            <Header/>
            <div className="content">
                <div className="divisor">
                    <div className="divisorTitle1">FAÇA SEU</div>
                    <div className="divisorTitle2"><hr className="line1" style={{width:100}}/>ORÇAMENTO</div>
                    <div className="divisorSubTitle">ENTRE EM CONTATO CONOSCO E FAÇA SEU ORÇAMENTO!</div>
                </div>
                <div className="container-orcamento">
                    <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={2} justifyContent="center">
                        <Grid item>
                            <div className="card-contato">
                                <div className="artista-contato">
                                    <img className="artista-foto" src="./images/studio.png"/>
                                    <div className="border-foto-back"/>
                                    <div className="border-foto-front"/>
                                </div>
                                <div className="contato-infos">
                                    <div className="nome">ESTUDIO TATTOO</div>
                                    <div className="insta-contato">@studiotattoo</div>
                                </div>
                                <div className="blockBtnAgendar">
                                    <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                                    <div className="btnAgenderBorder2"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="card-contato">
                                <div className="artista-contato">
                                    <img className="artista-foto" src="./images/artista1.png"/>
                                    <div className="border-foto-back"/>
                                    <div className="border-foto-front"/>
                                </div>
                                <div className="contato-infos">
                                    <div className="nome">LARISSA ARAUJO</div>
                                    <div className="insta-contato">@larissaaraujotattoo</div>
                                </div>
                                <div className="blockBtnAgendar">
                                    <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                                    <div className="btnAgenderBorder2"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="card-contato">
                                <div className="artista-contato">
                                    <img className="artista-foto" src="./images/artista2.png"/>
                                    <div className="border-foto-back"/>
                                    <div className="border-foto-front"/>
                                </div>
                                <div className="contato-infos">
                                    <div className="nome">CARLOS EDUARDO</div>
                                    <div className="insta-contato">@carlosedutattoo</div>
                                </div>
                                <div className="blockBtnAgendar">
                                    <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                                    <div className="btnAgenderBorder2"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="card-contato">
                                <div className="artista-contato">
                                    <img className="artista-foto" src="./images/artista3.png"/>
                                    <div className="border-foto-back"/>
                                    <div className="border-foto-front"/>
                                </div>
                                <div className="contato-infos">
                                    <div className="nome">FERNANDO SANTOS</div>
                                    <div className="insta-contato">@fersantospiercings</div>
                                </div>
                                <div className="blockBtnAgendar">
                                    <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                                    <div className="btnAgenderBorder2"/>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    
                    
                </div>
            </div> 
            <Footer page='CONTATOS'/>      
        </div>
    )
}
export default Orcamento;