import Footer from "../Components/footer";
import Header from "../Components/header";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Tattoo = () => {    
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [open5, setOpen5] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleOpen2 = () => {
        setOpen2(!open2)
    }
    const handleOpen3 = () => {
        setOpen3(!open3)
    }
    const handleOpen4 = () => {
        setOpen4(!open4)
    }
    const handleOpen5 = () => {
        setOpen5(!open5)
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="content">
                <div className="divisor">
                    <div className="divisorTitle1">TUDO SOBRE</div>
                    <div className="divisorTitle2"><hr className="line1" style={{width:100}}/>TATUAGENS</div>
                    <div className="divisorSubTitle">SAIBA UM POUCO MAIS SOBRE O MUNDO DA TATUAGENS!</div>
                </div>
                
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen}>
                            <div className="question-title">DICAS PARA ESCOLHER SUA TATUAGEM</div>
                            <div className={`icon-container ${open ? 'open':''}`}>
                                {open ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open ? 'open':''}`}>
                            <div className="answer-text">Antes de fazer uma tatuagem, especialmente a primeira, muitas pessoas passam tempo refletindo sobre a arte e o local do corpo a ser tatuado, considerando que é uma decisão permanente. Existem diversos estilos de tatuagem, como old school, minimalista, geométrico, entre outros, o que pode tornar a escolha difícil. Sugerimos que a escolha da arte deva refletir algo significativo para você, considerando tanto o presente quanto o futuro. Existem decisões impulsivas baseadas em modas passageiras e recomendamos atenção ao estilo de traço, sendo o "preto e cinza" e o "fine line" os mais procurados. Além disso, a escolha do tatuador é crucial, devendo-se pesquisar o estilo do artista se condiz com seu gosto.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen2}>
                            <div className="question-title">CUIDADOS COM A NOVA TATUAGEM</div>
                            <div className={`icon-container ${open2 ? 'open':''}`}>
                                {open2 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open2 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen3}>
                            <div className="question-title">ORIGEM DA TATUAGEM</div>
                            <div className={`icon-container ${open3 ? 'open':''}`}>
                                {open3 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open3 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen4}>
                            <div className="question-title">TATUAGEM PODE SER REMOVIDA?</div>
                            <div className={`icon-container ${open4 ? 'open':''}`}>
                                {open4 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open4 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                    <div className="container-faq">
                        <div className="question" onClick={handleOpen5}>
                            <div className="question-title">QUALIDADE DOS MATERIAIS</div>
                            <div className={`icon-container ${open5 ? 'open':''}`}>
                                {open5 ? (<ExpandMoreRoundedIcon sx={{fontSize:46}}/>):(<ExpandMoreRoundedIcon sx={{fontSize:46}}/>)}
                            </div>
                        </div>
                        <div className={`answer ${open5 ? 'open':''}`}>
                            <div className="answer-text">Texto de exemplo lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                        </div>
                    </div>
                
                <div className="agende">
                    <div className="agendeTitle1">AGENDE UM<hr className="line2-agende"/></div>
                    <div className="agendeTitle2"><hr className="line1-agende"/>HORÁRIO</div>
                    <div className="agendarSubTitle">GARANTIA DE QUALIDADE EM SUA TATTOO OU APLICAÇÃO DE PIERCINGS!</div>
                    <div className="blockBtnAgendar">
                        <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                        <div className="btnAgenderBorder2"/>
                    </div>
                </div>
            </div>  
            <Footer page='TATTOO'/>    
        </div>
    )
}
export default Tattoo;